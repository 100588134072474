import { camelCase, titleize } from "@/lib/string"
import classNames from "classnames"


export default function Tag ({type, label, noMargin, loading}) {
  const classes = classNames(
    "tag",
    type.replace(/ /g, '-'),
    {
      red: ['inactive'].includes(type),
    },
  )

  label = label || titleize( camelCase(type) )

  return (
    <>
      <span className={classes}>
        {label}
        <span className={classNames({loader: !!loading})}></span>
      </span>

      <style jsx>{`
        .tag {
          text-decoration: none;
          color: #377913;
          border-color: #377913;
          background-color: #d4fabf;
          margin: 0 ${noMargin ? '0' : '0.2rem'};
          position: relative;
          vertical-align: middle;
        }
        .green, .active {
          color: #377913;
          border-color: #377913;
          background-color: #d4fabf;
        }
        .dark-green, .converted {
          font-weight: 500;
          color: white;
          border-color: #397e13;
          background-color: #53a129;
        }
        .red, .internal_review, .required, .failed, .do-not-email, .churned, .preview-limbo, .deleted, .lead, .lead-status-missing, .unsubscribed, .blocked, .waiting_for_assistant {
          color: #a74040;
          border-color: #a74040;
          background-color: #fccfcf;
        }
        .dark-red, .churning {
          font-weight: 500;
          color: #fff;
          border-color: #fff;
          background-color: #c91c1c;
        }
        .orange, .in_progress, .open, .previewing, .canceling, .hidden, .pending, .informal, .demo, .waiting_for_customer, .waiting_for_assistant {
          color: #a36c07;
          border-color: #a36c07;
          background-color: #ff990059;
        }
        .canceled {
          color: #897041;
          border-color: #897041;
          background-color: #89704133;
        }
        .purple, .dei_review, .core, .in-progress, .draft, .email-clicked, .clicked, .attended, .attended-demo-call {
          color: #6e3b98;
          border-color: #6e3b98;
          background-color: #6e3b9833;
        }
        .dark-purple, .downloaded {
          font-weight: 500;
          color: white;
          border-color: #6e3b98;
          background-color: #666699;
        }
        .facebook {
          color: #1877F2;
          border-color: #1877F2;
          background-color: #1877F233;
        }
        .instagram {
          color: #E23523;
          border-color: #E23523;
          background-color: #E2352333;
        }
        .twitter {
          color: #1DA1F2;
          border-color: #1DA1F2;
          background-color: #1DA1F222;
        }
        .blue, .idea, .available, .added-card, .info, .email-opened, .opened {
          color: #1d4ff2;
          border-color: #1d4ff2;
          background-color: #1792df22;
        }
        .dark-blue, .in-trial {
          font-weight: 500;
          color: white;
          border-color: #1d4ff2;
          background-color: #3499CC;
        }
        .void, .refunded, .uncollectible, .expired, .non-starter, .preview-ended, .trial-expired, .stalled, .none, .retired, .not-yet, .skip-signup {
          color: #999;
          border-color: #999;
          background-color: #eee;
        }
        .yellow, .needs_images, .trialing, .ready {
          color: #7a7117;
          border-color: #7a7117;
          background-color: #e5e50093;
        }
        .tag .loader {
          border-color: inherit;
          border-right-color: transparent;
          border-top-color: transparent;
          margin-left: 0.4rem;
        }

      `}</style>
    </>
  )

}