import LoadingSpinner from "./LoadingSpinner"
import useCurrentOrg from "@/src/useCurrentOrg"
import Link from "next/link"
import { useAppRouter } from "@/src/useAppRouter"
import { max } from "@/lib/array"
import { useEffect, useState } from "react"
import rpc, { rpcTask } from "@/src/rpc"
import SimpleDate from "@/lib/SimpleDate"
import useRpc from "@/src/useRpc"


export default function Analytics () {
  const router = useAppRouter()

  const org = useCurrentOrg()

  if (!org) return <LoadingSpinner size="normal"/>

  if (!org.analyticsEnabled) {
    return <>
      <div className="px-4 py-5 text-center">
        <p className="red">
          Sorry. This feature is not available.
        </p>
        <p className="text-sm">
          Please <Link href={router.path('help')}>
            <a className="link">contact support</a>
          </Link> with any questions.
        </p>
      </div>
    </>
  }

  return <AnalyticsLoader org={org}/>
}


export function AnalyticsLoader ({org}) {
  const [metrics, setMetrics] = useState(null)

  const [socialStatus] = useRpc('social.status')

  useEffect(() => {
    fetchMetrics()

    async function fetchMetrics () {
      const task = await rpcTask('social.calculateMetricsInBackground', {orgId: org.id})
      await task.done()
      const viewsByMonth = await rpc('social.metricsForViews', {orgId: org.id})
      const postsPerMonth = await rpc('social.metricsForPostsPerMonth', {orgId: org.id})

      // Remove any leading zero value items
      for (let date in viewsByMonth) {
        if (viewsByMonth[date] === 0) delete viewsByMonth[date]
      }

      setMetrics({
        viewsByMonth,
        postsPerMonth,
      })
    }
  }, [])

  if (!metrics) return <LoadingSpinner size="normal"/>

  return <AnalyticsUI metrics={metrics} socialStatus={socialStatus}/>
}


export function AnalyticsUI ({metrics, socialStatus}) {

  const router = useAppRouter()

  const hasSocialAccounts = socialStatus && socialStatus.destinations.length > 0

  const { viewsByMonth, postsPerMonth } = metrics
  const maxViews = max(Object.values(viewsByMonth)) || 0
  const noData = Object.keys(viewsByMonth).length === 0

  // A simple bar chart to show the reach for each month over the last year

  return <div className="bg-f8f8f8 pb-5">

    <div className="px-4 py-5">
      <h2 className="text-lg font-bold mb-1">
        Posts
      </h2>
      <div className="gray text-sm mb-3">
        Number of posts on your calendar.
      </div>
      <div className="flex-row spread gap-3">
        <div className="flex-grow bg-white rounded shadow text-center p-2">
          <div className="text-md dark-gray no-bold">
            Last Month
          </div>
          <div className="text-2xl bold">
            {postsPerMonth.lastMonth.toLocaleString()}
          </div>
        </div>
        <div className="flex-grow bg-white rounded shadow text-center p-2">
          <div className="text-md dark-gray no-bold">
            This Month
          </div>
          <div className="text-2xl bold">
            {postsPerMonth.thisMonth.toLocaleString()}
          </div>
        </div>
      </div>
    </div>

    <div className="px-4 py-5">
      <h2 className="text-lg font-bold mb-1">
        Impressions
      </h2>
      <div className="gray text-sm mb-3">
        How many times people looked at your posts.
      </div>

      { noData ?
        <div className="gray text-sm shadow rounded p-4 py-5 bg-white">
          <p className="text-lg text-center bold">
            Nothing to Show (Yet)
          </p>
          { hasSocialAccounts ?
            <>
              <p>
                This usually means that you don't have any published posts, or
                that your posts haven't had a chance to accumulate enough
                traffic.
              </p>
              <p>
                This can also mean that <Link href={router.path('social', {highlight: 'accounts'})} >
                  <a className="link">
                    your social media accounts
                  </a>
                </Link> need to be reconnected, to enable analytics.
              </p>
            </>
          :
            <p>
              You need to <Link href={router.path('social', {highlight: 'connect'})}>
                <a className="link">connect your social accounts</a>
              </Link> and publish some social media posts.
            </p>
          }
        </div>
      :
        <div className="table shadow rounded gap-2 px-4 py-3 relative full-width text-sm">
          { Object.keys(viewsByMonth).map((date, i) => {
            const percentage = (viewsByMonth[date] / maxViews) * 100
            const d = new SimpleDate(date)
            return <div key={i} className="table-row">
              <div className="table-cell text-right nowrap">
                { d.monthName.substring(0, 3) } { d.year }
              </div>

              {/* Make this column of the grid grow to fill the remaining space */}
              <div className="table-cell px-2 py-1 full-width">
                <div className="bg-green" style={{height: '0.85rem', width : `${Math.round(percentage)}%`}}>
                </div>
              </div>

              <div className="table-cell gray text-right">
                { viewsByMonth[date].toLocaleString() }
              </div>
            </div>
          })}
        </div>
      }

    </div>
  </div>
}
