import { memo } from 'react'
import SettingsScreen from './SettingsScreen'
import Drawer from './Drawer'
import Head from 'next/head'
import TopNav from '@/components/TopNav'
import { useAppRouter } from '@/src/useAppRouter'
import classNames from 'classnames'
import AccountLocked from './AccountLocked'
import TrialBanner from './TrialBanner'
import { MonthView } from './MonthView'
import PostPanel from './PostPanel'
import useDeviceInfo from '@/src/useDeviceInfo'
import NavButton from "./NavButton.js";
import PostGroupEditor from './PostGroupEditor'
import PostGroupLocked from './PostGroupLocked'
import MonthViewNavigation from './MonthViewNavigation'
import useCurrentOrg from '@/src/useCurrentOrg'


export default memo(function HomeScreenPhone ({date, counts}) {
  const router = useAppRouter()

  const {popover} = router.query
  const org = useCurrentOrg()
  const cancelled = org && org.status === 'canceled'
  const inTrial = org?.status === 'trialing'

  const {landscapePhone} = useDeviceInfo()

  const sideways = !!landscapePhone

  return <>
    <Head>
      <meta name="apple-mobile-web-app-capable" content="yes"/>
      <link rel="apple-touch-icon" href="/app-icon.png"/>
    </Head>

    <div className={classNames("layout", {sideways})}>

      { cancelled && <AccountLocked/> }

      { !landscapePhone &&
        <div className="top-nav">
          <TopNav date={date} showLogo/>
        </div>
      }

      <div className='flex-grow month-nav phone'>
        <MonthViewNavigation date={date} />
      </div>

      <div className={classNames("main gray-background", {locked: cancelled})}>
        <div className="content-area">
          <div className="calendar-area flex-col overflow-hidden">
            { (!landscapePhone && inTrial) &&
              <div style={{marginTop: 1}}>
                <TrialBanner org={org} counts={counts}/>
              </div>
            }
            <MonthView date={date}/>
          </div>

        </div>
      </div>

      <Drawer position="bottom" title="Settings" active={popover === 'settings'}>
        <div style={{paddingBottom: '6rem'}}>
          <SettingsScreen date={date} showBack={true} />
        </div>
      </Drawer>

      <Drawer position="bottom" active={popover === 'create'}>
        <div className="drawer-content relative bg-purple full-height">
          <div className='absolute-fill overflow-scroll-y '>
            <PostPanel date={date}/>
          </div>
        </div>
      </Drawer>

      <Drawer position="bottom" active={popover === 'edit'}>
        <div className="full-height bg-eee">
          <PostGroupEditor date={date}/>
        </div>
      </Drawer>

      <Drawer position="bottom" active={popover === 'locked'}>
        <div className="full-height bg-eee">
          <PostGroupLocked date={date}/>
        </div>
      </Drawer>

      <nav className={classNames("bottom-nav")} role="navigation" aria-label="main navigation">
        <>
          <NavButton dark={true} name="calendar" />
          <NavButton dark={true} name="settings" />
        </>
      </nav>

    </div>


    <style jsx>{`
      .locked {
        filter: blur(3px);
        pointer-events: none;
      }
      .layout {
        display: flex;
        flex-direction: column;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        overflow: hidden;
        overscroll-behavior: contain;
      }
      .top-nav, .bottom-nav {
        flex-grow: 0;
        z-index: 13;
      }
      .bottom-nav {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        height: 60px;
        box-shadow: 0 0 4px rgba(0,0,0,0.5);
        z-index: 22;
        display: flex;
        justify-content: space-evenly;
        background-color: #31355B;
      }
      .sideways .bottom-nav {
        flex-direction: column;
        top: 0;
        right: unset;
        height: unset;
        width: 66px;
      }
      .main {
        display: flex;
        height: 100%;
        flex-grow: 1;
        overflow: hidden;
        overscroll-behavior: contain;
        flex-direction: column;
        padding-bottom: 60px;
      }
      .sideways .main {
        padding-bottom: unset;
        padding-left: 66px;
      }
      .content-area {
        display: flex;
        flex: 1;
        overflow-y: hidden;
        height: 100%;
      }
      .calendar-area {
        flex: 1 1 0;
      }
    `}</style>
  </>
})
