import { sortBy } from '@/lib/array'
import { useAppRouter } from '@/src/useAppRouter'
import { useCallback, useEffect, useRef, useState } from 'react'
import CloseIcon from './icons/CloseIcon'
import Link from "next/link";
import LoadingSpinner from './LoadingSpinner'
import Modal from './Modal'
import PostEditor from './PostEditor'
import rpc, { rpcTask } from '@/src/rpc'
import SimpleDate from '@/lib/SimpleDate'
import DestinationPicker from "./DestinationPicker";
import WarningIcon from "./icons/WarningIcon";
import withLoading from "@/src/withLoading";
import useDeviceInfo from "@/src/useDeviceInfo";
import { ordinalize } from '@/lib/string';
import TrashIcon from './icons/TrashIcon';
import { sendMessage } from '@/src/useMessage';
import useCurrentOrg from '@/src/useCurrentOrg';
import HandRaisedIcon from './icons/HandRaisedIcon';


export default function PostGroupEditor ({date, onClose, post}) {

  const router = useAppRouter()
  const {id} = router.query
  const org = useCurrentOrg()

  const connectSocialPath = router.path('social', {highlight: 'connect'})

  const {mobile, keyboardOnScreen, landscapePhone} = useDeviceInfo()

  const mountedAt = useRef()
  useEffect(() => {
    mountedAt.current = new Date().toISOString()
    return () => mountedAt.current = null
  }, [])

  const dataLoader = useRef()
  const [postGroup, setPostGroup] = useState()
  useEffect(() => {
    if (!id) return

    dataLoader.current = async function () {
      if (!mountedAt.current) return
      const currentPostGroup = await rpc('postGroups.find', {id})
      setPostGroup(currentPostGroup)
      if (currentPostGroup) {
        const {posts} = currentPostGroup
        setDestinationIds(posts.filter(p => !p.deletedAt).map(p => p.destinationId))
      }
    }

    dataLoader.current()
  }, [id])


  const reload = useCallback(() => {
    if (!dataLoader.current) return
    dataLoader.current()
  }, [])

  const [destinations, setDestinations] = useState([])
  const [destinationIds, setDestinationIds] = useState([])
  const [editingDestinations, setEditingDestinations] = useState(false)
  const [allowExport, setAllowExport] = useState(false)
  const [loadingMetrics, setLoadingMetrics] = useState(false)

  const destinationsSelected = destinations.filter(d => destinationIds.includes(d.id))


  const saveDestinations = useCallback(async function () {
    // If the post hasn't been saved yet, there's no need to notify the API
    if (postGroup.id) {
      await withLoading(async () => {
        await rpc('postGroups.setDestinationIds', {
          id: postGroup.id,
          destinationIds,
        })
        await reload()
      }, 100)
    } else {
      setPostGroup({...postGroup, posts: postGroup.posts.map(post => {
        return {
          ...post,
          deletedAt: destinationIds.includes(post.destinationId) ? null : new Date(),
        }
      })})
    }
    setEditingDestinations(false)
  }, [destinationIds, postGroup, reload])


  const needToSaveDestinations = useRef(false)

  useEffect(() => {
    if (!needToSaveDestinations.current) return
    needToSaveDestinations.current = false
    saveDestinations()
  }, [destinationIds, saveDestinations])


  useEffect(() => {
    if (!destinations.length) return

    if (id) return

    let scheduledAt = new Date(date.toString() + "T00:00:00")
    scheduledAt.setHours(typeof org.minPublishHour === 'undefined' ? 9 : org.minPublishHour)
    scheduledAt.setMinutes(0)
    scheduledAt.setSeconds(0)

    const earliest = new Date()
    earliest.setMinutes(earliest.getMinutes() + 30)
    earliest.setSeconds(0)

    if (scheduledAt < earliest) scheduledAt = earliest

    scheduledAt = scheduledAt.toISOString()

    let caption = post?.caption

    // Try to restore the caption from the last time they bailed out when creating a custom post
    if (!caption && window.localStorage) {
      const unsavedCaption = window.localStorage.getItem('unsavedCaption')
      if (unsavedCaption) caption = unsavedCaption
    }

    const newPostGroup = {
      date,
      posts: destinations.map((dest, i) => {
        return {
          ...post,
          caption,
          destinationId: dest.id,
          destination:   dest,
          scheduledAt,
          unified: i === 0,
        }
      })
    }

    setDestinationIds(destinations.map(d => d.id))

    setPostGroup(newPostGroup)
  }, [id, date, destinations])


  const TODAY = new SimpleDate()

  useEffect(() => {
    fetchSocialStatus()

    async function fetchSocialStatus () {
      const status = await rpc('social.status')

      // Auto-retry
      if (!status) return setTimeout(fetchSocialStatus, 500)

      // Avoid updating an unmouned component
      if (!mountedAt.current) return

      const {destinations} = status

      setDestinations(destinations)

      setAllowExport(status.allowExport)
    }
  }, [])


  if (!postGroup) return <>
    <div style={{padding: '2rem 0'}}>
      <LoadingSpinner size="medium" />
    </div>
  </>

  const postGroupDateInPast = postGroup.date < TODAY
  const wasPublished = !!postGroup.posts.find(post => post.publishedAt)
  const readonly = postGroupDateInPast || wasPublished

  async function handlePublish () {
    await reload()
  }

  async function handleDestinationsChange () {
    await reload()
    setDestinationIds(posts.filter(p => !p.deletedAt).map(p => p.destinationId))
  }

  let posts = sortBy(postGroup.posts, post => [
    post.destination?.platform,
    post.platform,
    post.destination?.name,
    post.id,
  ])

  const primaryPost = posts.find(p => p.unified)
  const unified = !!primaryPost

  let postGroupDestinations = posts.filter(post => !post.deletedAt).map(post => post.destination)
  const connectedDestinations = postGroupDestinations.filter(dest => dest?.targetId)
  if (!id) {
    postGroupDestinations = connectedDestinations
  }

  const isSaved = !!postGroup?.id
  const hasDestination = destinations.filter(d => d.platform !== 'social').length > 0
  const showConnectionWarning = false // isSaved && !hasDestination && !postGroupDateInPast
  const approvalNeeded = isSaved && postGroup?.approvalRequired && !postGroup?.approvedAt && hasDestination
  const allowPublish = isSaved && hasDestination && !readonly && !postGroupDateInPast && !approvalNeeded


  // With a unified post group, only one post needs to be edited
  if (unified) {
    posts = [primaryPost]
  } else if (connectedDestinations.length) {
    // When split, hide any posts that belong to destinations with no target
    posts = posts.filter(post => {
      return !!post.destination?.targetId
    })
  } else if (posts.length > 1) {
    posts = posts.filter(post => post.platform !== 'social')
  }

  const canUnify = !!(!readonly && !unified && connectedDestinations.length > 1)
  const canSplit = !!(!readonly &&  unified && connectedDestinations.length > 1) && !!postGroup?.id
  const canExport = !!postGroup?.id && (allowExport || postGroup?.allowExport)
  const canRemove = !!postGroup?.id && !!(org?.demo || !readonly)

  const hasImage = !!primaryPost?.imageId
  const hasCaption = (primaryPost?.caption || '').trim().length > 0
  const imageRequiredToAdd = !!connectedDestinations.find(dest => dest.platform === 'instagram')

  const canAddToCalendar = (hasImage || !imageRequiredToAdd) && hasCaption // && captionErrors.length === 0


  const bottomBarStyle = {}
  if (mobile) {
    if (keyboardOnScreen) {
      bottomBarStyle.display = 'none'
    } else {
      bottomBarStyle.position = 'fixed'
      bottomBarStyle.left = 0
      bottomBarStyle.right = 0
      bottomBarStyle.bottom = landscapePhone ? 36 : 96 // 60px bottom nav + 36px padding on popover
    }
  }

  const showLinkToAddAccount = !readonly && !!id

  function editDestinations () {
    if (destinations.filter(d => d.targetId).length === 0) {
      router.push(connectSocialPath)
    } else {
      setEditingDestinations(true)
    }
  }


  async function handlePostChanged (newPost) {
    if (postGroup.id) return

    setPostGroup({...postGroup, posts: postGroup.posts.map(post => {
      return {
        ...post,
        caption:     newPost.caption,
        scheduledAt: newPost.scheduledAt,
        imageId:     newPost.imageId,
      }
    })})
  }


  function onRemovePlatforms (platforms) {
    needToSaveDestinations.current = true
    setDestinationIds(ids => ids.filter(id => {
      const destination = destinations.find(d => d.id === id)
      console.log(destination.platform, !platforms.includes(destination.platform))
      return !platforms.includes(destination.platform)
    }))
  }


  async function handleApprove (e) {
    if (e.preventDefault) e.preventDefault()
    if (postGroup.id) {
      await rpc('postGroups.approve', {id: postGroup.id})
      reload()
      sendMessage('refreshCalendar')
    }
  }

  async function handleReloadMetrics () {
    if (!readonly) return
    if (loadingMetrics) return

    setLoadingMetrics(true)
    try {
      const task = await rpcTask('postGroups.reloadMetricsInBackground', {id: postGroup.id})
      await task.done()

      await reload()
    } finally {
      setLoadingMetrics(false)
    }
  }

  async function handleSplit () {
    if (postGroup.id) {
      await rpc('postGroups.split', {id: postGroup.id})
      reload()
    } else {
      setPostGroup({...postGroup, posts: postGroup.posts.map(post => {
        return {
          ...post,
          unified: false,
        }
      })})
    }
  }

  async function handleUnify () {
    if (postGroup.id) {
      await rpc('postGroups.unify', {id: postGroup.id})
      reload()
    } else {
      setPostGroup({...postGroup, posts: postGroup.posts.map((post, i) => {
        return {
          ...post,
          unified: i === 0,
        }
      })})
    }
  }

  async function addToCalendar () {
    const primaryPost = posts.find(p => p.unified)
    const data = {
      caption:        primaryPost.caption,
      scheduledAt:    primaryPost.scheduledAt,
      imageId:        primaryPost.imageId,
      eventId:        primaryPost.eventId,
      date:           date,
      destinationIds: destinationIds,
    }
    await rpc('postGroups.create', data)
    sendMessage('refreshCalendar')
    if (window.localStorage) {
      window.localStorage.removeItem('unsavedCaption')
    }
    router.goto(date)
  }


  async function handleClose () {
    if (onClose) {
      onClose()
    } else {
      router.goto(date)
    }
  }

  async function handleDelete () {
    await rpc('postGroups.destroy', {id: postGroup.id})
    router.goto(date)
    sendMessage('refreshCalendar')
  }


  let pgDate = null
  try {
    pgDate = new SimpleDate(postGroup.date)
  } catch (err) {
    console.warn(`Couldn't parse postgroup date ${postGroup.date}`)
    console.error(err)
  }


  return <>
    <div className='post-group-editor bg-eee flex flex-col' style={{maxHeight: 'calc(100vh - 2rem)'}}>
      <div className='flex-row spread bg-white shadow z-1 px-2'>
        <div className='flex-row flex-grow flex-same'>
          { canRemove &&
            <div className='flex p-2 my-1 pointer bg-hover-light-gray' onClick={handleDelete}>
              <TrashIcon />
            </div>
          }
        </div>

        <div className='flex flex-shrink'>
          { !!(readonly || id) ?
            <>
              {pgDate ?
                <span>
                  {pgDate.monthName.substring(0, 3)} {ordinalize(pgDate.dayOfMonth)}, {pgDate.year}
                </span>
              :
                'Post'
              }
            </>
          :
            'Create a Post'
          }
        </div>

        <div className='flex-row flex-grow flex-same justify-flex-end'>
          <div className="flex p-2 my-1 pointer bg-hover-light-gray" data-testid="close-post-group-button" onClick={handleClose}>
            <CloseIcon/>
          </div>
        </div>
      </div>

      <div className="flex-grow overflow-auto-y">

        { showConnectionWarning ?
          <div className="flex-row gap-1 bg-yellow orange mt--1px border-yellow flex-start flex-justify-center px-2 py-3">
            <div className="flex-no-shrink flex">
              <WarningIcon/>
            </div>
            <span>This post won't be published unless you <Link href={connectSocialPath}>
              <a className="link">connect your social media accounts</a>
            </Link>.</span>
          </div>
        : approvalNeeded ?
          <div className="sm_flex-row gap-2 bg-yellow mt--1px border-yellow flex-center p-2">
            <div className="flex-row orange gap-1 ">
              { !readonly &&
                <HandRaisedIcon/>
              }
              <span className='orange nowrap' style={{fontSize: 15}}>
                { readonly ?
                  `This post wasn't approved`
                :
                  `This post needs approval`
                }
                <span className='extra-approval-text'>,
                  { readonly ?
                    ` so it couldn't be published`
                  :
                    ` before it can be published`
                  }
                </span>.
              </span>
            </div>
            { !readonly &&
              <div className=''>
                <button className='button small bold is-primary' onClick={handleApprove}>
                  Approve
                </button>
              </div>
            }
          </div>
        : postGroup.greatMetrics ?
          <div className="sm_flex-row gap-2 bg-purple mt--1px border-blue flex-center p-2 z-4">
            <div className="flex-row flex-center gap-1 white ">
              <span className='bold'>
                <div className='inline-block mx-1 relative'>
                  <span className='dancing inline-block star-shadow'>⭐️</span>
                </div>
                This post was popular
              </span>
            </div>
          </div>
        :
          null
        }

        <div className='p-2 pb-4'>
          <div className='flex-col gap-4'>
            {
              posts.map((post, i) => {
                const postData = {
                  ...post,
                  date: postGroup.date,
                  deletedAt: unified ? postGroup.deletedAt : post.deletedAt,
                  postGroupId: postGroup.id,
                }

                const postDestinations = [post.destination].filter(v => v)

                let key = post.id || `new-post-${i}`

                // Need this to create a different key (when unified/split) to
                // update the list of destinations shown on each post.
                if (unified) key += '-unified'

                const activeDestinations = unified ? postGroupDestinations : postDestinations
                const imageRequired = activeDestinations.find(dest => dest.platform === 'instagram')

                return (
                  <PostEditor
                    key={key}
                    post={postData}
                    readonly={readonly}
                    unified={unified}
                    imageRequired={imageRequired}
                    destinations={activeDestinations}
                    socialAccountsConnected={hasDestination}
                    onEditDestinations={editDestinations}
                    onDestinationsChange={handleDestinationsChange}
                    onPublish={handlePublish}
                    showDeletedAfter={mountedAt.current}
                    allowExport={canExport}
                    allowPublish={allowPublish}
                    onChange={handlePostChanged}
                    onReloadMetrics={handleReloadMetrics}
                    handleSplit={canSplit && i === 0 ? handleSplit : null}
                    handleUnify={canUnify && i === 0 ? handleUnify : null}
                    onRemovePlatforms={readonly ? ()=>{} : onRemovePlatforms}
                    loadingMetrics={loadingMetrics}
                    wasPublished={wasPublished}
                  />
                )
              })
            }
          </div>
        </div>

        { mobile &&
          <div className="py-6"></div>
        }
      </div>

      { !postGroup?.id &&
        <div className="p-2 bg-white full-width shadow-top z-3" style={bottomBarStyle}>
          <div className="flex-grow flex-row mx-auto gap-2" style={{maxWidth: 200}}>
            <button className="save-button flex-grow is-primary button" onClick={addToCalendar} disabled={!canAddToCalendar}>
              Add to Calendar
            </button>
          </div>
        </div>
      }
    </div>

    { editingDestinations &&
      <Modal visible={true}>
        <div className="p-4 grid gap-2">
          <h2 className="text-lg bold">
            Destinations
          </h2>

          <div className="flex-col bg-white">
            <DestinationPicker
              available={destinations.filter(dest => dest.targetId)}
              selectedIds={destinationIds}
              onChange={setDestinationIds}
              posts={postGroup.posts}
              disabled={readonly}
            />
          </div>

          { showLinkToAddAccount &&
            <Link href={router.path('social', {highlight: 'connect'})}>
              <a className="link center small">
                Add {destinations && destinations.length > 0 ? 'another' : 'an'} account
              </a>
            </Link>
          }

          { destinationsSelected.length === 0 &&
            <div className="small red center">
              Please pick at least one.
            </div>
          }

          <button
            className="button is-primary margin-auto mt-2"
            disabled={destinationIds.length === 0}
            onClick={readonly ? () => setEditingDestinations(false) : saveDestinations}>
            OK
          </button>
        </div>
      </Modal>
    }

    <style jsx>{`
      .bg-hover-light-gray {
        border: 1px solid transparent;
        border-radius: 0.5rem;
        transition: all 150ms;
      }
      .bg-hover-light-gray:hover {
        background-color: #eee;
        border-color: #ddd;
      }
      @media screen and (min-width: 900px) {
        .post-group-editor {
          width: 850px;
        }
      }
      @media screen and (max-width: 765px) {
        .padded-panel {
          padding-bottom: 4rem !important;
        }
      }
      @media screen and (max-width: 500px) {
        .extra-approval-text {
          display: none;
        }
      }

      .star-shadow {
        filter: drop-shadow(0 4px 3px rgba(0, 0, 0, 0.2));
      }

      .dancing {
        animation: dance 1s;
        animation-iteration-count: infinite;
      }

      @keyframes dance {
        0%  { transform: translate3d(-1px, 1px, 0) rotate(0deg); }
        25% { transform: translate3d(0, -1px, 0)  rotate(-10deg); }
        50% { transform: translate3d(1px, 1px, 0) rotate(0deg); }
        75% { transform: translate3d(0, -1px, 0)  rotate(10deg); }
        100% { transform: translate3d(-1px, 1px, 0) rotate(0deg); }
      }


    `}</style>
  </>
}
