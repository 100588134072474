import { useEffect, useRef, useState } from "react"
import SetupProgress from "./SetupProgress"
import useRpc from '@/src/useRpc'
import { useAppRouter } from "@/src/useAppRouter"
import ProgressBar from "./ProgressBar"
import { sendMessage } from "@/src/useMessage"
import LoadingSpinner from "./LoadingSpinner"
import rpc from "@/src/rpc"


export default function OnboardingSteps ({org}) {
  const showOnboardingSteps = org && org.showSetupSteps

  const router = useAppRouter()

  const [billing] = useRpc('billing.info')
  const [status]  = useRpc('social.status', {})

  const [brandingSkipped, setBrandingSkipped] = useState(false)

  const mounted = useRef(false)
  useEffect(() => {
    mounted.current = true
    return () => mounted.current = false
  }, [])

  const partner = billing?.partner
  const partnerId = org?.partnerId
  const loadingParter = partnerId && !partner

  async function skipBranding () {
    setBrandingSkipped(true)
    try {
      const res = await rpc('orgs.skipBranding', {}, {throwErrors: true})
    } catch (err) {
      console.error(err)
      sendMessage('error', "Sorry. That didn't work 🤔")
      if (mounted.current) setBrandingSkipped(false)
    }
  }

  function setupSteps () {
    if (!status || !org) return []

    const {destinations} = status
    const activeDestinations = (destinations || []).filter(d => d.targetId)

    const calendarBuilt = !org.nextSignupUrl
    const socialCompleted = activeDestinations && activeDestinations.length > 0
    const billingAddressCompleted = org && org.zip
    const brandingCompleted = org.brandChangedAt || brandingSkipped
    const paymentComplete = billing && !!billing.creditCard

    const stepList = []

    stepList.push({
      name: <span>Build Social Media Calendar</span>,
      complete: calendarBuilt,
      href: '',
    })

    stepList.push({
      name: <span>Add Logos and Colors</span>,
      skip: skipBranding,
      complete: brandingCompleted,
      href: router.path('brand'),
    })

    if (org?.status !== 'lead') {
      stepList.push({
        name: <span>Enter Company Address</span>,
        complete: billingAddressCompleted,
        href: router.path('subscription'),
        callback: () => sendMessage('editOrg')
      })

      stepList.push({
        name: <span>Connect Social Accounts</span>,
        complete: socialCompleted,
        href: router.path('social', {highlight: 'connect'}),
      })

      if (!partner?.collectsPayment && billing?.nextBillingAmount > 0) {
        stepList.push({
          name: <span>Add Payment Info</span>,
          complete: paymentComplete,
          href: router.path('subscription', {highlight: 'add-card'}),
        })
      }
    }

    return stepList
  }

  const steps = setupSteps()

  // When calculating progress ignore any fake steps
  const realSteps = steps.filter(s => !s.fake)

  const progress = Math.round(realSteps.filter(s => s.complete).length * 100 / realSteps.length)

  const onboardingInProgress = steps.length && steps.filter(s => !s.complete).length > 0

  if (!org) return null

  if (!showOnboardingSteps) return null
  if (!onboardingInProgress) return null
  if (loadingParter) return <LoadingSpinner size="medium"/>

  return <>
    <div className="onboarding-steps px-4 pt-3 pb-5 shadow border border-light-purple">
      <div className="center-text max-width-350 margin-auto">
        <div className="flex-row flex-align-center">
          <div className="flex-row flex-start gap-3 py-2 px-1 bold">
            Setup Progress
          </div>
          <div style={{width: 100, flexGrow: 1, marginLeft: '0.5rem', marginTop: '0.2rem'}}>
            <ProgressBar value={progress} showLabel color="#666699"/>
          </div>
        </div>
        <div>
          <SetupProgress steps={steps}/>
        </div>
      </div>
    </div>

    <style jsx>{`
      .onboarding-steps {
        position: sticky;
        top: 0;
      }
    `}</style>
  </>
}
